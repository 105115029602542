import React from "react"
import { graphql } from "gatsby"

import "../styles/templates/special-terms-and-conditions.scss"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { html } = markdownRemark

  return (
    <div className="special-terms-and-conditions">
      <div
        className="text-content"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </div>
  )
}

export const pageQuery = graphql`
  query($originalPath: String!, $locale: String!) {
    markdownRemark(
      frontmatter: { path: { eq: $originalPath }, locale: { eq: $locale } }
    ) {
      html
      frontmatter {
        path
        title
        subtitle
      }
    }
  }
`
